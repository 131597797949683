 .not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
}
 
.animated-404 {
  font-size: 10rem;
  color: #FF9E00;
  display: flex;
  gap: 10px;
}
 
.animated-404 span {
  display: inline-block;
  animation: bounce 1s infinite alternate;
}
 
.animated-404 span:nth-child(1) {
  animation-delay: 0s;
}
 
.animated-404 span:nth-child(2) {
  animation-delay: 0.2s;
}
 
.animated-404 span:nth-child(3) {
  animation-delay: 0.4s;
}
 
@keyframes bounce {
  to {
    transform: translateY(-30px);
  }
}
 
.not-found-container p {
  font-size: 1.5rem;
  /* margin: 20px 0; */
  color: #555;
}
 
.home-link {
  display: inline-block;
  padding: 15px 30px;
  background-color: #FF9E00;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
 
.home-link:hover {
  background-color: #FF9E00;
  transform: scale(1.05);
}