.main-layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background-color: #F8F8F8;
}

.content-wrapper{
    display: flex;
    flex-direction: column;
}
/* .content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    z-index: 1;
} */

.content-wrappers{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #F8F8F8;
}



.content-wrapper .content {
    flex-shrink: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

 .sidebar.visible {
     display: block;
     transform: translateX(0);
     position: absolute;
 }
/* .content {
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
} */

.content>.navbar {
    flex-shrink: 0;
}

.content>.main-content {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #F8F8F8;
    padding: 20px;
    /* Add padding for content */
}

.container-fluid{
    height: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9991;
}

.sidebar.visible {
    z-index: 9999;
}


@media (max-width: 768px) {
   .content-wrappers {
        margin-top: 25px;
    }
}
