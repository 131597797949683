.highlighted-word {
   color: #7542E4;
    border-bottom: 1px solid #7542E4; /* Example: Red border */

  }


.delete-icon{
  display: none;
}

.dragging{
  box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 5px;
}

.react-datepicker-wrapper {
  /* padding: 10px; */
  width: 100%;
}

.exercise{
    width: 100% !important;
  }
  .answers{
    width: 100% !important;
  }

.publish-button{
  left: 39px;
}

.configs-image-upload-div{
  width: 100%;
}

@media (max-width: 1030px) {
.publish-button{
  left: 0px;
  padding: 0% !important;
  width: 100%;
  margin: 0% !important;
}
}

@media (max-width: 769px) {
  .custom-config-div{
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .publish-button{
  left: 0px;
  padding: 0% !important;
  width: 100%;
  margin: 0% !important;
}

  .main-page {
    padding: 0% !important;
  }

  .class-main {
    padding: 0px !important;
  }
  
  .profile-main{
    gap: 20px !important;
  }
  .user-profile{
    flex: none;
    width: 100%;
    padding: 0% !important;
  }
  .user-activity{
     width: 100%;
    padding: 0% !important;
  }

  .main-container {
    gap: 20px !important;
    padding: 0% !important;
    margin: 0% !important;
  }

  .theme-container {
    flex: none;
  }

  .theme-content {
    gap: 10px;
    display: grid;
  }

  .theme-box {
    width: 100%;
  }
  .container-title{
   width: auto;
  }
  .pairs-row {
    flex-wrap: wrap !important;
    gap: 10px;
    padding: 0%;
  }
  .pairs-dropdown {
    padding: 0%;
  }

  .pairs-input {
    padding: 0%;
  }

  .main-flashcard {
    gap: 20px !important;
    padding: 0% !important;
  }

  .flashCard-Content{
    /* display: contents !important; */
    /* justify-content: center !important; */
     flex-direction: column;
  }
  .theme-Content{
    flex-direction: column;
  }
  .flashCardContent-button{
    padding: 8px 30px !important;
  }
  .dragging{
  box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border-radius: 10px !important;
  padding: 5px !important;
  /* background-color: blueviolet !important; */
}
 .edit-deleteIcon{
   width: 100% !important; 
   justify-content: center !important;
 }
  .preview-content {
    flex: none;
  }
  .preview-container {
    padding: 10px !important;
  }
  .type-container {
    padding: 20px !important;
  }
  .type-contnet {
    flex: none;
  }

  .block-text-container {
    width: 100%;
    padding: 10px !important;
  }
  .verb-title {
    width: 100%;
    padding: 0% !important;
    flex-direction: column;
    /* display: unset !important; */
    gap: 10px !important;
    align-items: center;
    justify-content: flex-start;
  }
  .static-type {
    width: 100% !important;
  }
  .verb-items {
    width: 100% !important;
    gap: 0% !important;
    padding: 0% !important;
  }
  .items {
    width: 100% !important;
  }
  .verb-input {
    width: 100% !important;
  }
  /* example css*/
  .example-container {
    width: 100%;
    padding: 10px !important;
  }
  .example-top {
    flex-direction: column;
    gap: 15px;
  }
  .horizontal-space {
    width: 0% !important;
  }
  .header-input {
    padding: 0px !important;
  }
  .sentence-example {
    padding: 0% !important;
    gap: 10px !important;
  }
  
  .sentence-row {
    margin-top: 0%;
    margin-right: 0%;
    margin-left: 0%;
    flex-wrap: wrap !important;
    width: -webkit-fill-available;
    /* flex-direction: column !important; */
    gap: 10px;
  }

  .sentence-dropdown{
    flex: none !important;
    padding: 0% !important;
    flex-direction: row;
    gap: 10px;
  }

  .sentence-input{
    align-items: unset !important;
    /* margin: 0% !important; */
    padding: 0% !important;
  }

  .delete-icon{
    display: block;
  }
  
  .delete-button{
    display: none;
  }
  .denkansto-sentence {
    padding: 0% !important;
    width: 100%;
  }
  .denkansto-row {
    gap: 10px !important;
    padding: 0% !important;
    width: 100% !important;
  }
  .denkansto-col {
    padding: 0% !important;
    margin: 0% !important;
    width: 100% !important;
  }

  .notification-container{
    flex-direction: column !important;
  }
  .notification-content{
    flex-direction: row;
  }
  .textFields-container{
     flex-direction: column !important;
  }
  .textFields-content{
    flex-direction: row;
  }
  .drag-drop-content{
     justify-content: space-between;
     flex-direction: row !important;
  }
  .multipleOption-Content{
    flex-direction: column !important;
    padding: 0% !important;
  }

  .bretzel-container{
    padding: 0% !important;
  }
  .bretzel-content{
    padding: 0% !important;
  }
  .bretzel-type{
    flex: unset;
  }
   .grammar-group-container{
    padding: 0% !important;
  }
  .grammar-group-content{
    padding: 0% !important;
  }
  .grammar-group-type{
    flex: unset;
  }

  .particle-list-content{
    flex-direction: column;
    width: 100%;
  }

  .particle-list-one{
    padding: 0% !important;
  }

  .particle-list-two{
    padding: 0% !important;
  }

  .particles-list-row{
    align-items: center !important;
    flex-wrap: wrap !important;
  }

  .particle-list-dropdown{
     flex: none !important;
    padding: 0% !important;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }
  
  .particle-list-input{
    align-items: unset !important;
    /* margin: 0% !important; */
    padding: 0% !important;
    width: 100%;
  }

  .conjugation-list-content{
    flex-direction: column !important;
    gap: 15px !important;
  }

  .conjugation-list-column{
    flex: unset !important;
    width: 100%;
  }

  .inputbox-content{
     max-width: 100%;
  }

  .conjugation-list-inputbox{
    flex-direction: column !important;
    gap: 15px !important;
  }

  .verticalspace-conjugation{
    height: 10px !important;
  }
  
  .exercise-answers-container{
    padding: 10px !important;
  }
  .exercise-answers{
    flex-direction: column !important;
  }
  .exercise{
    width: 100% !important;
  }
  .answers{
    width: 100% !important;
  }
}
