/* radiobutton.css */
.custom-radio .form-check-input {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    /* Make it circular for radio button */
    margin-top: 0px;
    margin-left: 5px;
    cursor: pointer;
    
}

.custom-radio .form-check-input:checked {
    background-color: #7542e4;
    border: none;
}

.custom-radio .form-check-input:not(:checked) {
    background-color: #262323;
    opacity: 30%;
    border: none;
}