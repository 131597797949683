.drop_box {
    margin: 10px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 3px dotted #a3a3a3; */
    border-radius: 10px;
    background-color: #ffffff;
}

.drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop_box p {
    /* margin-top: 10px; */
    /* margin-bottom: 20px; */
    font-size: 12px;
    color: #a3a3a3;
}

@media (max-width: 768px) {
    .main-page{
        padding: 0% !important;
    }

   .class-main{
     padding: 0px !important;
    }
   
    .color-set{
        flex-direction: column !important;
    }

    .item-color{
        flex-direction: row !important;
        width: 100% ;
        justify-content: space-between !important;
    }
    .image-text{
        display: revert-layer !important;
    }

    .main-container{
        gap: 20px !important;
        padding: 0% !important;
        margin: 0% !important;
    }

    .lesson-container{
        flex: none;
    }
    .theme-container{
        flex: none;
    }

    .theme-content{
        gap: 10px;
        display: grid;
    }

   .lessons-top{
    flex-direction: column;
    gap: 10px;
    align-items: flex-start !important;
   }

   .theme-box{
    width: 100%;
   }

   .heading{
    margin-top: 20px;
   }

    .particle-example {
    padding: 0% !important;
    gap: 10px !important;
  }
  
  .particle-row {
    margin-top: 0%;
    margin-right: 0%;
    margin-left: 0%;
    flex-wrap: wrap !important;
    width: -webkit-fill-available;
    /* flex-direction: column !important; */
    gap: 10px;
  }

  .particle-dropdown{
    flex: none !important;
    padding: 0% !important;
    flex-direction: row;
    gap: 10px;
  }

  .particle-input{
    align-items: unset !important;
    /* margin: 0% !important; */
    padding: 0% !important;
  }
}