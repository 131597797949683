/* header.css */
.navbar {
    width: 100%;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* z-index: 1000; */
    padding: 20px;
}

/* <<---  SEARCH INPUT STYLING --->>  */
.search-input-container {
    position: relative;
}

.search-input {
    padding: 0.3em 1em;
    border: none;
    border-radius: 6px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    max-width: 200px;
    color: #333;
    border: 0.5px solid black !important;

}

.search-input:hover {
    background-color: #f2f2f2 !important;
}

.search-input:focus {
    outline: none;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6) !important;
}

.search-input::placeholder {
    color: #999;
}

.search-highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #6c63ff !important;
    transition: width 0.3s ease;
}

.search-input-container:focus+.search-highlight {
    width: 100%;
}




/* Optional: Animation on focus */
@keyframes input-focus {
    from {
        transform: scale(1);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    }

    to {
        transform: scale(1.02);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.input:focus {
    animation: input-focus 0.3s ease;
}


.slide-button {
    display: none;
}

@media screen and (max-width: 1100px) {
    .slide-button {
        display: block;
        border: none;

    }
}

@media (max-width: 488px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 160px !important;
    }
}

@media (max-width: 458px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 140px !important;
    }
}

@media (max-width: 398px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 120px !important;
    }
}

@media (max-width: 380px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 90px !important;
    }
}

@media (max-width: 350px) {
    .navbar {
        top: 10px;
        padding: 0px !important;
    }

    .div-control {
        width: 75px !important;
    }
}