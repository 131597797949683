/* NestedMenu.css */
.nestedMenuItem {
    color: #333 !important;
    display: flex;
    padding: 2px 5px !important;
    border-radius: 4px !important;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 10px;
    width: 197px !important;
}

.nestedMenuItem:hover {
    background-color: #D9D9D9 !important;
}

@media (max-width: 576px) {
    .nested-menu-container {
        width: 100% !important;
    }
}

.menuItem {
    color: #333 !important;
    padding: 2px 5px !important;
    border-radius: 4px !important;
    color: #000 !important;
    width: 197px !important;    
    gap: 8px !important;
}

.menuItem:hover {
    background-color: #D9D9D9 !important;
}

.menu-items-container {
  /* background-color: red; */
  border-radius: 10px;
  gap: 15px;
  padding: 9px 8px;
  display: flex;
  flex-direction: column;
}

.custom-menu-paper{
    border-radius: 10px;
    color: red;
    gap: 10px;
}


.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{
    opacity: 1;
    transform: none;
    transition: opacity 257ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 171ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    
    transform-origin: 0px 0px;  
    border-radius: 10px;
}

/* LessonsDropdown.css */
.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
