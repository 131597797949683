/* Default styling for the progress bar */
progress {
  /* width: 100%; */
  height: 12px;
  /* -webkit-appearance: none; */
  /* appearance: none; */
  border-radius: 6px;
  /* overflow: hidden; */
}

/* Webkit-specific styling for Chrome/Safari */
progress::-webkit-progress-bar {
  background-color: #e0e0e0;
}

progress::-webkit-progress-value {
  border-radius: 6px;
}

.progress-bar-red::-webkit-progress-value {
  background-color: #eb002c;
}

.progress-bar-green::-webkit-progress-value {
  background-color: #28a138;
}

progress::-moz-progress-bar {
  background-color: #28a138;
}

.progress-bar-red::-moz-progress-bar {
  background-color: #eb002c;
}

.progress-bar-green::-moz-progress-bar {
  background-color: #28a138;
}
