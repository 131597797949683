/* styles.css */
.custom-checkbox .form-check-input {
    width: 28px;
    height: 28px;
    border-radius: 10px;
    margin-top: 0px;
    margin-left: 5px;

}

.custom-checkbox .form-check-input:checked {    
    background-color: #7542e4;
    border: none;
    font-size: "10px";
    font-weight: normal;
    
}

.custom-checkbox .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 10 3 3 6-6'/%3e%3c/svg%3e);
}

.custom-checkbox .form-check-input:not(:checked) {
    background-color: #d9d9d9;
    border: none;
}
