/* sidebar.css */
.sidebar {
    background-color: black;
    border-radius: 0px 20px 20px 0px;
    z-index: 9999;
    /* transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); */
}
/* 
.sidebar.visible {
    transform: translateX(0);
    
} */

@media (max-width: 1100px) {
    .sidebar {
        display: none;
    }

    .sidebar.visible .sidebar{
        flex-direction: column !important;
        display: block !important;
        align-items: flex-start !important;
        border-radius: 0px 20px 20px 0px !important;
        transform: translateX(0);
    }
    .sidebar-content{
       
    flex-direction: column !important;
    /* display: block !important; */
    }
    .sidebar-menu{
        flex-direction: column !important;
        align-items: center !important;
    }
    .sidebar-bottem{
      /* position: absolute; */
      /* bottom: 20px; */
      /* width: 20px; */
      align-items: center !important;
    }
}

.nav-pills .nav-item:hover svg {
    fill: #ffffff;
}

.dropdown:hover svg {
    fill: #ffffff;
}

.sidebar-scrollbar::-webkit-scrollbar {
    display: none;
  }