.sidebar ul {
    list-style-type: none;
    padding: 0;
    
}

.sidebar ul li {
    margin-bottom: 12px;
    /* margin-right: 10px; */
     -webkit-user-select: none;
     user-select: none;  
}

.sidebar ul li a {
    display: block;
    text-decoration: none;
}

.sidebar ul li a img {
    width: 20px;
    /* Adjust the width as needed */
    height: 20px;
    /* Adjust the height as needed */
}

/* Set default color to #dadada */
.default-color {
    filter: brightness(0.3) invert(35%) ;
}

/* Change SVG color on hover */
.sidebar ul li a img:hover {
    filter: brightness(0) invert(1);
    /* Change the color to white on hover */
}