/* Shimmer loader wrapper */
.shimmer-loader-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

/* Shimmer loader */
.shimmer-loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;

}


.shimmer {
    position: absolute;
    top: 0;
    left: -50%;
    width: 150%;
    height: 100%;
    background: linear-gradient(to right, rgba(240, 240, 240, 0.6) 0%, rgba(240, 240, 240, 0.8) 50%, rgba(240, 240, 240, 0.6) 100%);
    animation: shimmer-animation 2s infinite linear;
}

.spinner {
    position: absolute;
    /* top: 50%;
    left: 50%; */
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #000000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Shimmer animation keyframes */
@keyframes shimmer-animation {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

/* Spinner animation keyframes */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}