.jodit-container:not(.jodit_inline) {
  border: none !important;
}

.text-editor .css-fupe6q{
  border: 1px solid #ccc;
}

.text-editor .css-mk2oaz{
  height: 50px;
}
.text-editor .css-xabbgz{
  height: 50px;
}

.text-editor .css-de4hfa-MuiButtonBase-root-MuiButton-root{
  /* display: none !important; */
   color: rgba(26,26,26,0.87) !important;
}
.text-editor .css-de4hfa-MuiButtonBase-root-MuiButton-root .css-1jxdcj3-MuiSvgIcon-root{
  color: rgba(26,26,26,0.87) !important;
}
.text-editor .css-e5lzpv-MuiButtonBase-root-MuiButton-root{
  /* display: none !important; */
 color: #e6e6e6 !important;
}
.text-editor .css-e5lzpv-MuiButtonBase-root-MuiButton-root .css-1jxdcj3-MuiSvgIcon-root{
  color: #e6e6e6;
}
