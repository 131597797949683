@media (max-width: 768px) {
    .main-responsive{
        gap: 10px;
        padding: 0;
    }

    .all-button-responsive{
        gap: 10px;
        padding: 0;
         flex-direction: column;
    }

.responsive{
    gap: 10px;
    justify-content: space-between !important;
    flex-direction: row;
    padding: 0;
}

.header-page{
    gap: 10px;
    justify-content: space-between !important;
    flex-direction: column;
    padding: 0;
}

.search-input-div{
    padding: 0;
    width: 100%;
/* gap: 10px; */
}

.tickets-filter{
     width: 100% !important;
     justify-content: center !important;
     align-items: center !important;
}

.tickets-count{
    width: 100% !important;
    justify-content: center !important;
    display: grid !important;
    grid-template-columns: repeat(2,auto) !important;
}
.users-count{
    width: 100% !important;
    justify-content: center !important;
    display: grid !important;
    grid-template-columns: repeat(2,auto) !important;
}
}