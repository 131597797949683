.user-box {
  transition: box-shadow 0.3s ease;
  /* Smooth transition */
}

.user-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect on hover */
}

.user {
  transition: box-shadow 0.3s ease;
  /* Smooth transition */
}

.user:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.user-scale {
  transition: all 0.3s ease;
}

.user-scale:hover {
  transform: scale(1.2);
}

.dashboaed-scale {
  transition: all 0.3s ease;
}

.dashboaed-scale:hover {
  transform: scale(1.03);
}

.dashboaed-score {
  transition: all 0.3s ease;
}

.dashboaed-score:hover {
  transform: scale(1.2);
}

.dashboard-pie {
  transition: all 0.3s ease;
}

.dashboard-pie:hover {
  transform: scale(1.1);
}

/* In your CSS file */
.user-container-hover {
  transition: background-color 0.3s ease;
}

.user-container-hover:hover {
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
}

.go-back {
  transition: all 0.3s ease;
}

.go-back:hover {
  /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3); */
  transform: scale(1.1);
}

.scrollable-container {
  max-height: 250px;
  overflow-y: auto;
}

.scrollable-container::-webkit-scrollbar {
  width: 5px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
  border: 3none;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.scrollable-container::-webkit-scrollbar-button {
  height: 35px;
}

.scrollable-packages {
  max-height: 225px;
  overflow-y: auto;
}

.scrollable-packages::-webkit-scrollbar {
  width: 5px;
}

.scrollable-packages::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-packages::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
  border: 3none;
}

.scrollable-packages::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.scrollable-packages::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
}

.scrollable-packages::-webkit-scrollbar-button {
  height: 35px;
}


.scrollable-lessons-container {
  max-height: 261px;
  overflow-y: auto;
}

.scrollable-lessons-container::-webkit-scrollbar {
  width: 5px;
}

.scrollable-lessons-container::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-lessons-container::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
  border: 3none;
}

.scrollable-lessons-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.scrollable-lessons-container::-webkit-scrollbar-button {
  height: 35px;
}

.block-text-component p {
  margin: 0;
}


.column-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Hide scrollbar for Firefox */
.column-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.bg-transparent {
  background-color: #7542E4 !important;
  transition: all 0.3s ease;
}

.bg-transparent:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .custom-width {
    width: 100%;
  }
}