.custom-toggle.dropdown-toggle::after {
    display: none;
    /* Hide the caret icon */
}
#dropdown-basic{
    background-color: #ffffff;
    color: #100F15;
    border: 1px solid #100F15;
    padding: 10px 15px;
    display: flex;
    width: 160px;
}
.custom-menu{
    padding: 10px;
    width: -webkit-fill-available;
}
.custom-dropdown .dropdown-menu {
    min-width: calc(100% - 1rem);
    /* Set the minimum width of the dropdown menu */
}
.selected-item {
    background-color: #7542e4;
    /* Custom background color for the selected item */
    color: #ffffff;
    border-radius: 10px;
    /* Custom text color for the selected item */
}
.selected-item:hover {
    background-color: #7542e4;
    /* Custom background color for the selected item */
    color: #ffffff;
    border-radius: 10px;
    /* Custom text color for the selected item */
}
.custom-item {
    margin-bottom: 4px;
    margin-top: 4px;
    /* Add bottom space to the items */
}
.custom-item:hover {
    background-color: #7542e4 !important;
    color: #ffffff !important;
    border-radius: 10px;
}