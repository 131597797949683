.fullscreen-background {
    /* background-color: #100f15; */
    height:100vh;
    gap: 20px;
    /* Make the background cover the entire viewport height */
}

.fullscreen-content {
    height: 100%;
    /* gap: 20px; */
    /* padding: 100px; */
    /* Ensure the content fills the full height of the screen */
}

.btn{
    background-color: #7542E4;
    border: 0px solid ;
    width: 140px;
}



@media (max-width: 768px) {
        .col {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        /* .lexik_name{
            height: 64px;
        } */
        .fullscreen-background{
            /* height: auto; */
            /* background-color: aqua; */
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            /* height: ; */
         }
        .fullscreen-content{
         display: contents;
         align-items: center;
         justify-content: center;
         gap: 20px;        
         /* background-color: #7542E4; */
        }
        .logo{
            width: 90px;
        }
        .lexik_name{
            width: 310px;
        }

        .login-image {
            padding: 0 !important;
        }

        .input-box{
            padding: 30px 18px 0px 20px !important;
        }

        .button{
            display: flex;
            justify-content: center;
        }
    }