.switch {
    width: 44px;
    height: 24px;
    background-color: red;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
}

.switch-toggle {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: 8%;
    top: 13%;
    transform: translateY(-50%);
    transition: transform 0.3s, background-color 0.3s;
}

.switch.on {
    background-color: green;
}

.switch.on .switch-toggle {
    transform: translateX(20px);
    left: 6%;
}

.switch.off .switch-toggle {
    transform: translateX(0);
}