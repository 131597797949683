.privacy-policy {
  margin: 30px 200px;
  font-family: "Inter", sans-serif;
}

.centered-heading {
  justify-content: center;
  display: flex;
  font-size: 42px;
  line-height: 57px;
  font-weight: 800;
  color: #1D3557;
}

.heading-three {
  font-size: 25px;
  margin: 20px 0px;
   font-weight: 700;
  color: #1D3557;
}

.heading-four {
  color: #1D3557;
}

.bold {
  color: #1D3557;
}

ol {
  color: #1D3557;
}

.p-color {
  color: #1D3557;
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0px;
}

.contact-info {
  color: #1D3557;
}

.contact-detail {
  font-size: 14px;
  margin: 1px 0px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .centered-heading {
    font-size: 32px;
    line-height: 45px;
  }

  .heading-three {
    font-size: 23px;
  }

  .p-color {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .privacy-policy {
    margin: 10px 20px;
  }

  .heading-three {
    font-size: 22px;
  }

  .centered-heading {
    font-size: 30px;
    line-height: 40px;
  }

  .p-color {
    font-size: 14px;
    line-height: 20px;
  }
}
